import { MessageEditorWithMediaData } from "piramis-base-components/src/components/MessageEditorWithMedia/types"
import { PollPost, QuizPost, Schedule, PostState, IPostMessage } from "piramis-base-components/src/shared/modules/posting/types"

// todo review delayed actions \ reactions

export type CreatedPost = {
  key: string
  post: {
    message: IPostMessage | PollPost | QuizPost
    delayed_actions: Array<PostAction> | null
    reaction_actions: Array<PostReaction> | null
  }
  schedule?: Schedule | null
  owner_id?: number
  description: string | null
  state: PostState
  targets: Array<number>
  run_time: Array<string>

  category?: null
  creative_id?: null
  tags?: Array<any>
}

export type CalendarPosts = CreatedPost & { postTime: string }

export type PostToCreate = Pick<CreatedPost, 'post' | 'schedule' | 'targets'> & {
  board_key?: string
  board?: string
  style: Style
  origin?: number | null
  topic_config: Record<number, Array<number>>
}

export type EditPublishedPost = {
  board_key: string
  post_key: string
  message: IPostMessage['variants'][0]
  style?: Style
}

export type Style = {
  id?: any,
  watermark_type?: WatermarkType | null
  watermark_data?: any
  watermark_position?: WatermarkPosition | null
  prefix_message?: Array<MessageEditorWithMediaData> | null
  postfix_message?: Array<MessageEditorWithMediaData> | null
  protect_content: boolean
  disable_notify: boolean
  disable_link_preview: boolean
  pin: boolean
}

export enum WatermarkType {
  None = "None",
  File = "File",
  Text = "Text"
}

export enum WatermarkPosition {
  Flip = "Flip",
  Center = "Center",
  TopRight = "TopRight",
  TopLeft = "TopLeft",
  BottomRight = "BottomRight",
  BottomLeft = "BottomLeft",
}

export type Conditions = {
  type: string
  values: any
}

export type Period = {
  interval: number | null
  unit: string
  conditions: Array<Conditions> | null
}

export type PostAction = {
  delay: number,
} & IPostActionMessage

export type PostReaction = {
  reaction: string,
  lower_bound: number,
} & IPostActionMessage

export type IPostActionMessage = {
  action: {
    type: PostActionType
    message?: IPostMessage,
  },
}

export enum PostActionType {
  DeletePostAction = "DeletePostAction",
  SendCommentAction = "SendCommentAction",
  EditPostAction = "EditPostAction",
  UnpinChatMessageAction = "UnpinChatMessageAction"
}

export type Attachments = {
  type: string
  file: string
}

export type MessageVariants = {
  board?: string,
  chat_id?: number,
  text: string
  buttons: Array<any>
  pin?: boolean
  attachments?: Array<Attachments>,
  send_after?: number
  disable_notify?: boolean
  remove_previous?: boolean
  remove_after?: number,
  disable_link_preview?: boolean
}

export type Board = {
  avatar: string
  board: {
    token_id: number,
    token_code: string,
  }
  title: string,
  timezone: string,
  targets: Array<Target>
}

export type Target = {
  id: number,
  photo: string,
  title: string,
  weight: number
  code: any,
  topics?: Array<{id: number, title: string}>
  channel?: TargetChannel
}

export type TargetChannel = {
  id: number
  code: string
  title: string
  photo: string
}
