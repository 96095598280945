var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"config-page"},[_c('page-title',{attrs:{"has-anchor-icon":false}}),_c('a-spin',{staticClass:"w-full",attrs:{"spinning":_vm.isStyleLoading}},[(_vm.isObjectSet && _vm.boardTokenId)?_c('a-card',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('text-input',{staticClass:"mt-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.style,
              'key': 'name',
              'prefix': 'post_template_',
              'validation': 'required',
            }
          }}}),_c('post-styles-setup',{attrs:{"board":_vm.boardTokenId},model:{value:(_vm.styleObject),callback:function ($$v) {_vm.styleObject=$$v},expression:"styleObject"}}),_c('div',{staticClass:"buttons-wrapper mt-5 gap-2 flex justify-end"},[_c('a-button',{staticClass:"btn-success",attrs:{"disabled":invalid || _vm.disabled},on:{"click":_vm.saveStyle}},[_vm._v(" "+_vm._s(_vm.$t('save_button'))+" ")]),_c('a-button',{attrs:{"type":"danger"},on:{"click":function (){ return _vm.$router.back(); }}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])],1)]}}],null,false,1971592229)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }